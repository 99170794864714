import {
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const AdminPage = () => {
  const [students, setStudents] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const searchInput = useRef();
  const [boys, setBoys] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudents().then((data) => {
        setStudents(data.data.girls);
        setBoys(data.data.boys);

        console.log(data.data);
      });
    }
  }, [initialRender]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "Full name",
      dataIndex: "fullname",
      ...getColumnSearchProps("fullname"),
      sorter: (a, b) => GlobalVariables.compare(a.fullname, b.fullname),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => GlobalVariables.compare(a.gender, b.gender),
    },
    {
      title: "Year",
      dataIndex: "year",
      ...getColumnSearchProps("year"),
      sorter: (a, b) => GlobalVariables.compare(a.year, b.year),
    },
    {
      title: "P.G-1 Full name",
      dataIndex: "gfullname",
      ...getColumnSearchProps("gfullname"),
      sorter: (a, b) => GlobalVariables.compare(a.gfullname, b.gfullname),
    },
    {
      title: "P.G-1 Phone",
      dataIndex: "gphone",
      ...getColumnSearchProps("gphone"),
      sorter: (a, b) => GlobalVariables.compare(a.gphone, b.gphone),
    },
    {
      title: "P.G-1 Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
    },
    {
      title: "P.G-2 Full name",
      dataIndex: "gfullname2",
      ...getColumnSearchProps("gfullname2"),
      sorter: (a, b) => GlobalVariables.compare(a.gfullname2, b.gfullname2),
    },
    {
      title: "P.G-2 Phone",
      dataIndex: "gphone2",
      ...getColumnSearchProps("gphone2"),
      sorter: (a, b) => GlobalVariables.compare(a.gphone2, b.gphone2),
    },
    {
      title: "P.G-2 Email",
      dataIndex: "email2",
      ...getColumnSearchProps("email2"),
      sorter: (a, b) => GlobalVariables.compare(a.email2, b.email2),
    },
    {
      title: "Enrol Date",
      dataIndex: "createdOn",
      render: (cretedOn) => moment(cretedOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Days",
      dataIndex: "days",
      render: (days) =>
        days === 1 ? "Only Fridays" : days === 2 ? "Only Saturdays" : "2 Days",
    },
    {
      title: "Is Paid?",
      dataIndex: "isPaid",
      render: (isPaid) => (isPaid ? "YES" : "NO"),
    },
    {
      title: "Term",
      dataIndex: "term",
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          {/* <Tooltip title="Delete Student" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteStudent(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip> */}
          <Tooltip title="Other Details" placement="left">
            <Button
              type="primary"
              ghost
              onClick={() => goModal(item)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Full name",
      dataIndex: "fullname",
      ...getColumnSearchProps("fullname"),
      sorter: (a, b) => GlobalVariables.compare(a.fullname, b.fullname),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => GlobalVariables.compare(a.gender, b.gender),
    },
    {
      title: "Year",
      dataIndex: "year",
      ...getColumnSearchProps("year"),
      sorter: (a, b) => GlobalVariables.compare(a.year, b.year),
    },
    {
      title: "Guardian",
      dataIndex: "gfullname",
      ...getColumnSearchProps("gfullname"),
      sorter: (a, b) => GlobalVariables.compare(a.gfullname, b.gfullname),
    },
    {
      title: "Phone",
      dataIndex: "gphone",
      ...getColumnSearchProps("gphone"),
      sorter: (a, b) => GlobalVariables.compare(a.gphone, b.gphone),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => GlobalVariables.compare(a.email, b.email),
    },
    {
      title: "Enrol Date",
      dataIndex: "createdOn",
      render: (cretedOn) => moment(cretedOn).format("DD-MM-YY HH:mm"),
    },
    {
      title: "Is Paid?",
      dataIndex: "isPaid",
      render: (isPaid) => (isPaid ? "YES" : "NO"),
    },
    {
      title: "Term",
      dataIndex: "term",
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          {/* <Tooltip title="Delete Student" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteStudentBoy(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip> */}
          <Tooltip title="Other Details" placement="left">
            <Button
              type="primary"
              ghost
              onClick={() => goModal(item)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const deleteStudent = (id) => {
    console.log(id);
    AdminService.deleteStudent(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Student Deleted!",
      });
      setInitialRender(true);
    });
  };
  const deleteStudentBoy = (id) => {
    console.log(id);
    AdminService.deleteStudentBoy(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Student Deleted!",
      });
      setInitialRender(true);
    });
  };
  const goModal = (item) => {
    setSelectedStudent(item);
    setVisible(true);
  };

  const exportExcel = () => {
    AdminService.getExcel().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "students.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const onFinish = (values) => {
    const req = {
      message: quillValue,
      ...form.getFieldsValue(),
    };
    AdminService.sendEmailToGuardians(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Messages sent successfully!",
      });
      form.resetFields();
      setQuillValue("");
    });
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  return (
    <div className="mx-auto container min-h-screen">
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        title={selectedStudent?.fullname}
      >
        {selectedStudent && (
          <Descriptions bordered column={1} size="small" className="-m-2">
            <Descriptions.Item label="Qur'an Level">
              {selectedStudent.level}
            </Descriptions.Item>
            <Descriptions.Item label="Emergency Contact">
              {selectedStudent.efullname}
            </Descriptions.Item>
            <Descriptions.Item label="Emergency Phone">
              {selectedStudent.ephone}
            </Descriptions.Item>
            <Descriptions.Item label="Health Conditions">
              {selectedStudent.health}
            </Descriptions.Item>
            <Descriptions.Item label="Allergie">
              {selectedStudent.allergie}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
      <br />
      <img
        className="relative mx-auto mb-4"
        width={250}
        src={require("../assets/images/logo.png").default}
        alt="logo"
      />
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl pb-24">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
            VALUES EDUCATION STUDENTS
          </h2>
        </div>
        <br />
        {!lg && (
          <div className=" flex items-center justify-center bg-gray-50 bg-opacity-80 rounded-lg py-6 px-4 sm:px-6 lg:px-8 shadow-lg border border-gray-400">
            <div className="max-w-md w-full space-y-8 text-center">
              <div>
                <p>
                  Your screen size is very small. <br />
                  Large screen required for this page.
                  <br />
                  Please connect desktop computer.
                  <br /> Thank you
                </p>
                <br />
              </div>
            </div>
          </div>
        )}
        {lg && (
          <>
            {" "}
            {/* <Button
              style={{ float: "right" }}
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={exportExcel}
            >
              Export Excel
            </Button> */}
            <Table
              title={() =>
                students && (
                  <span className="text-amazonl font-semibold text-base">
                    Total Students (GIRLS) : {students.length}
                  </span>
                )
              }
              dataSource={students}
              bordered
              pagination={false}
              columns={columns}
              size="small"
              loading={students === null}
              scroll={{ x: 1000 }}
            />
            <br /> <br /> <br />{" "}
            <Table
              title={() =>
                boys && (
                  <span className="text-amazonl font-semibold text-base">
                    Total Students (BOYS) : {boys.length}
                  </span>
                )
              }
              dataSource={boys}
              bordered
              pagination={false}
              columns={columns2}
              size="small"
              loading={boys === null}
            />
            {/* {students && students.length > 0 && (
              <>
                <Divider orientation="center">
                  Send mail to all guardians
                </Divider>
                <Form
                  className="max-w-4xl"
                  form={form}
                  layout={lg ? "horizontal" : "vertical"}
                  onFinish={onFinish}
                  {...GlobalVariables.formLayout}
                >
                  <Form.Item label="Mail Subject" name="subject">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Mail Message">
                    <ReactQuill
                      modules={modules}
                      theme="snow"
                      onChange={(e) => quillChange(e)}
                      placeholder="Type news long details"
                    />
                  </Form.Item>

                  <Form.Item {...GlobalVariables.tailLayout}>
                    <Popconfirm
                      title="Are you sure ?"
                      onConfirm={onFinish}
                      okText="Yes! Send."
                      cancelText="Cancel"
                    >
                      <Button type="primary" danger icon={<MailOutlined />}>
                        Send Email to All Guardians
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Form>
              </>
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
