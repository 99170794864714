import { Card, Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const SelectGender = () => {
  const router = useHistory();
  return (
    <div
      className="h-screen flex justify-center"
      style={{ paddingTop: "100px" }}
    >
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative mb-12">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              VALUES EDUCATION
              <br />
              <span className="text-red-600">TERM-3</span> ENROLMENT FORM
            </h2>
          </div>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Card
                className="border border-gray-300 p-4 hover:shadow-lg cursor-pointer"
                onClick={() => router.push("/girls")}
              >
                <p className="uppercase text-lg text-sky-500 font-bold">
                  For Girls
                </p>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                className="border border-gray-300 p-4 hover:shadow-lg cursor-pointer"
                onClick={() => router.push("/boys")}
              >
                <p className="uppercase text-lg text-sky-500 font-bold">
                  For Boys
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SelectGender;
