import { axios } from "./HttpClient";

export const AdminService = {
  newStudent: (values) => {
    return axios.post("/donations/newstudent", values);
  },
  newStudentBoy: (values) => {
    return axios.post("/donations/newstudentboy", values);
  },
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  getStudents: () => {
    return axios.get("/erks");
  },
  getExcel: () => {
    return axios.get("erks/excel", { responseType: "blob" });
  },
  deleteStudent: (id) => {
    return axios.delete("/erks/" + id);
  },
  deleteStudentBoy: (id) => {
    return axios.delete("/erks/boy/" + id);
  },
  sendEmailToGuardians: (values) => {
    return axios.post("/erks/email", values);
  },
  finalizeOrder: (values) => {
    return axios.post("/donations/sendFinalOrder", values);
  },
  finalizeOrderBoys: (values) => {
    return axios.post("/donations/sendFinalOrderBoys", values);
  },
  newAssessments: (values) => {
    return axios.post("/assessments", values);
  },
  checkExistingGirl: (fullname) => {
    return axios.get("/erks/checkexistinggirl?fullname=" + fullname);
  },
  checkExistingBoy: (fullname) => {
    return axios.get("/erks/checkexistingboy?fullname=" + fullname);
  },
};
