import {
  ClearOutlined,
  CreditCardOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  notification,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

export default function KayitFormuBoys() {
  const router = useHistory();
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [isAllergic, setIsAllergic] = useState(false);
  const { lg } = useBreakpoint();
  const [imageURL, setImageURL] = useState(null);
  const [sigModal, setSigModal] = useState(false);
  const sigCanvas = useRef({});
  const [onay, setOnay] = useState(true);
  const [ogrenci, setOgrenci] = useState("......");
  const [veli, setVeli] = useState("......");
  const [sinif, setSinif] = useState("......");
  const [ucretli, setUcretli] = useState(true);
  const [loading, setLoading] = useState(false);

  /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const close = () => setSigModal(false);

  const onSubmit = () => {
    var values = form.getFieldsValue();
    console.log(values);
    if (imageURL === null) {
      notification.error({
        message: "Error",
        description: "Please sign",
      });
      return;
    }
    values = { ...values, signature: imageURL };
    AdminService.newStudentBoy(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Student saved!",
      });
      onReset();
      router.replace("/completed");
    });
  };

  const onSubmitUcretli = useCallback(
    async function (values) {
      //    event.preventDefault();
      // if (imageURL === null) {
      //   notification.error({
      //     message: "Error!",
      //     description: "Please sign the form.",
      //   });
      //   return;
      // }
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        values = { ...values, signature: imageURL };

        let response = await AdminService.finalizeOrderBoys({
          ...values,
          newsite: true,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });

        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrderBoys({
            ...values,
            newsite: true,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your payment!",
          });

          form.resetFields();
          router.replace("/completed");
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form, router]
  );

  // const onFinish = (values) => {
  //   console.log(values);
  //   if (imageURL === null) {
  //     notification.error({
  //       message: "Hata",
  //       description: "Imza atmaniz gerekmektedir",
  //     });
  //     return;
  //   }
  //   values = { ...values, signature: imageURL };
  //   AdminService.newStudent(values).then((data) => {
  //     notification.success({
  //       message: "Basarili!",
  //       description: "Ogrenci kaydedildi!",
  //     });
  //     onReset();
  //     router.replace("/completed");
  //   });
  // };
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setSigModal(false);
  };
  const onReset = () => {
    form.resetFields();
    setOgrenci(".....");
    setSinif(".....");
    setVeli("......");
  };
  const allergieChanged = (v) => {
    console.log(v);

    v === "Yes" ? setIsAllergic(true) : setIsAllergic(false);
  };
  const onChange = (e) => {
    setOnay(e.target.checked);
  };
  const formChanged = (a, b) => {
    setOgrenci(form.getFieldValue("fullname"));
    setVeli(form.getFieldValue("gfullname"));
    setSinif(form.getFieldValue("year"));
  };
  const yearChanged = (v) => {
    console.log(v);
    // if (v === "Year 11") {
    //   setUcretli(false);
    // } else {
    //   setUcretli(true);
    // }
  };
  const checkChanged = (v) => {
    console.log(v);
    if (v.target.checked) {
      setUcretli(false);
    } else {
      setUcretli(true);
    }
  };
  const checkChanged2 = (v) => {
    console.log(v);
    if (v.target.checked) {
      setUcretli(true);
    } else {
      setUcretli(false);
    }
  };
  return (
    <div className="container mx-auto min-h-screen">
      <Modal visible={sigModal} onCancel={close} footer={null} closable={false}>
        <div>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          {/* Button to trigger save canvas image */}
          <Space className="mt-3" size="small">
            <Button type="primary" icon={<SaveOutlined />} onClick={save}>
              Save
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200"
              icon={<ClearOutlined />}
              onClick={clear}
            >
              Clear
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200 text-red-600"
              onClick={() => setSigModal(false)}
            >
              Close
            </Button>
          </Space>
        </div>
      </Modal>
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              VALUES EDUCATION TERM-3
              <br />
              ENROLMENT FORM (FOR BOYS)
            </h2>
            <br />
          </div>
          <div className="border border-gray-300 bg-f5f5f5 p-2 lg:p-8 shadow-lg mx-auto max-w-4xl">
            <Form
              form={form}
              onFinish={onSubmitUcretli}
              onFieldsChange={formChanged}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Student fullname"
                name="fullname"
                rules={[{ required: true, message: "This field required!" }]}
                help="If it is a past student, the form will be filled automatically."
              >
                <Input
                  placeholder="Type student fullname"
                  onBlur={(v) => {
                    console.log("blur", v.target.value);
                    AdminService.checkExistingBoy(v.target.value).then(
                      (data) => {
                        console.log(data.data);
                        form.setFieldsValue(data.data);
                      }
                    );
                  }}
                />
              </Form.Item>
              {/* <Form.Item
                label="Cinsiyet"
                name="gender"
                rules={[{ required: true, message: "Gender is required!" }]}
              >
                <Select placeholder="Lütfen cinsiyet seçiniz">
                  {["Erkek", "Kız"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item
                label="Year level"
                name="year"
                rules={[{ required: true, message: "Year Level is required!" }]}
              >
                <Select placeholder="Select year level" onChange={yearChanged}>
                  {["Year 7", "Year 8", "Year 9", "Year 10", "Year 11"].map(
                    (g) => (
                      <Select.Option key={g} value={g}>
                        {g}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Does your child <br />
                    have allergies
                  </span>
                }
                name="isAllergie"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select" onChange={allergieChanged}>
                  {["Yes", "No"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {isAllergic && (
                <Form.Item
                  label="Details"
                  name="allergie"
                  rules={[{ required: true, message: "Details is required!" }]}
                >
                  <Input placeholder="Please type details" />
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  Are there any health conditions about your child that we
                  should know about?
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                name="health"
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <Input placeholder="Type details (if any)" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">Parent Details</p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Parent fullname"
                name="gfullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Type parent fullname" />
              </Form.Item>
              <Form.Item
                label="Parent Phone"
                name="gphone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Type parent phone" />
              </Form.Item>
              <Form.Item
                label="Parent e-mail"
                name="email"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Type parent e-mail address" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">
                  Emergency Contact (Different from parent)
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Contact Fullname"
                name="efullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Type fullname" />
              </Form.Item>
              <Form.Item
                label="Contact Phone"
                name="ephone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Type phone" />
              </Form.Item>

              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  <b>RISK AWARENESS & PERMISSION SLIP</b>{" "}
                </p>
                <p>
                  Galaxy Foundation Values Education Program administrates /
                  convenes / participates in a number of welfare activities in
                  which many students, including students of Amity College,
                  participate. While Galaxy Foundation takes measures to ensure
                  the welfare activities are as safe as possible for
                  participants, there is still a risk that students can be
                  injured as a result of their participation in these welfare
                  activities.
                </p>
                <p>
                  Injury may occur while the student is engaging in an activity
                  or travelling to and from an activity. The injury may result
                  from a student’s actions or that of others. In order to
                  prevent any serious injury/incident, students must avoid
                  engaging in any dangerous act and conduct themselves safely at
                  all times. If a student has a pre-existing injury,
                  participating in these activities could result in an
                  exacerbation of that injury. It is the student’s
                  responsibility to ensure that their belongings are not lost,
                  stolen, damaged or destroyed.
                </p>
                <p>
                  <b>I understand that:</b>
                </p>{" "}
                <p>
                  ● I will authorise the teacher in charge to consent, where it
                  is impracticable to communicate with me, to the child
                  receiving such medical treatment as may be deemed necessary.{" "}
                  <br />
                  ● I will receive an information letter containing information
                  as to the specific excursion details prior to the excursion
                  occurring <br />● If I wish to withdraw my consent for my
                  child to participate in the excursion / activity I will advise
                  the Values Education Team in writing as soon as possible.
                </p>
              </Form.Item>
              <Form.Item label="Parent Consent">
                <Checkbox onChange={onChange} defaultChecked={true}>
                  I, <b>{veli} </b> consent to my child <b>{ogrenci}</b> of
                  class <b>{sinif}</b> to participate in the excursions /
                  activities.
                </Checkbox>
              </Form.Item>
              <Form.Item label="Signature" required>
                <Button
                  onClick={() => setSigModal(true)}
                  className="text-sky-600"
                >
                  Open signature panel
                </Button>

                <br />
                <br />

                {imageURL ? (
                  <img
                    src={imageURL}
                    alt="my signature"
                    style={{
                      border: "1px solid #ddd",
                      padding: "15px",
                      backgroundColor: "white",
                      width: "150px",
                    }}
                  />
                ) : null}
              </Form.Item>
              {ucretli && (
                <>
                  <Form.Item
                    wrapperCol={
                      lg && {
                        offset: 6,
                        span: 18,
                      }
                    }
                  >
                    <h4 className="text-base">
                      If you like to make the payment now, please fill in the
                      details below.
                      <br />
                      <br />
                      Please Type Your Credit/Debit Card Details
                    </h4>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "18px",
                            fontFamily: "monospace",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "red",
                          },
                        },
                      }}
                      className="st-form"
                    />
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      disabled={!stripe || !elements || !onay || !imageURL}
                      block
                      style={{ maxWidth: "500px" }}
                      size="large"
                      icon={<CreditCardOutlined />}
                    >
                      Pay $150 and Submit Form
                    </Button>
                  </Form.Item>
                </>
              )}
              {/* <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  block
                  size="large"
                  style={{ maxWidth: "500px" }}
                  disabled={!onay || !imageURL}
                  onClick={() => onSubmit()}
                >
                  Submit Form without payment
                </Button> */}
              <br />
              <br />

              <Button
                type="default"
                htmlType="reset"
                style={{ maxWidth: "500px" }}
                icon={<ClearOutlined />}
                size="large"
                block
              >
                Clear Form
              </Button>
              <br />
            </Form>
            {ucretli && (
              <img
                src={require("../assets/images/secure-stripe.png").default}
                alt="secure"
                className="w-full max-w-md mx-auto"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
