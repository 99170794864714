import {
  ClearOutlined,
  CreditCardOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  notification,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

export default function KayitFormu() {
  const router = useHistory();
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [isAllergic, setIsAllergic] = useState(false);
  const { lg } = useBreakpoint();
  const [imageURL, setImageURL] = useState(null);
  const [sigModal, setSigModal] = useState(false);
  const sigCanvas = useRef({});
  const [onay, setOnay] = useState(true);
  const [ogrenci, setOgrenci] = useState("......");
  const [veli, setVeli] = useState("......");
  const [sinif, setSinif] = useState("......");
  const [ucretli, setUcretli] = useState(true);
  const [days, setDays] = useState(3);
  /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const close = () => setSigModal(false);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();

      var values = form.getFieldsValue();
      console.log(values);
      if (imageURL === null) {
        notification.error({
          message: "Error",
          description: "Please sign",
        });
        return;
      }
      values = { ...values, signature: imageURL };
      AdminService.newStudent(values).then((data) => {
        notification.success({
          message: "Success!",
          description: "Student saved!",
        });
        onReset();
        router.replace("/completed");
      });
    } catch (errors) {
      notification.error({
        message: "Error",
        description: "Please fill all required fields",
      });
    }
  }, [form, imageURL, router]);

  const onFinish = useCallback(
    async function (values) {
      //    event.preventDefault();
      // if (imageURL === null) {
      //   notification.error({
      //     message: "Error!",
      //     description: "Please sign the form.",
      //   });
      //   return;
      // }
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        values = { ...values, signature: imageURL };

        let response = await AdminService.finalizeOrder({
          ...values,
          signature: imageURL,
          newsite: true,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });

        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }
          values = { ...values, signature: imageURL };
          response = await AdminService.finalizeOrder({
            ...values,
            signature: imageURL,
            newsite: true,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your payment!",
          });

          form.resetFields();
          router.replace("/completed");
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form, router]
  );

  // const onFinish = (values) => {
  //   console.log(values);
  //   if (imageURL === null) {
  //     notification.error({
  //       message: "Hata",
  //       description: "Imza atmaniz gerekmektedir",
  //     });
  //     return;
  //   }
  //   values = { ...values, signature: imageURL };
  //   AdminService.newStudent(values).then((data) => {
  //     notification.success({
  //       message: "Basarili!",
  //       description: "Ogrenci kaydedildi!",
  //     });
  //     onReset();
  //     router.replace("/completed");
  //   });
  // };
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setSigModal(false);
  };

  const onReset = () => {
    form.resetFields();
    setOgrenci(".....");
    setSinif(".....");
    setVeli("......");
  };
  const allergieChanged = (v) => {
    console.log(v);

    v === "Yes" ? setIsAllergic(true) : setIsAllergic(false);
  };
  const onChange = (e) => {
    setOnay(e.target.checked);
  };
  const formChanged = (a, b) => {
    setOgrenci(form.getFieldValue("fullname"));
    setVeli(form.getFieldValue("gfullname"));
    setSinif(form.getFieldValue("year"));
  };
  const yearChanged = (v) => {
    console.log(v);
    // if (v === "Year 11") {
    //   setUcretli(false);
    // } else {
    //   setUcretli(true);
    // }
  };
  const checkChanged = (v) => {
    console.log(v);
    // if (v.target.checked) {
    //   setUcretli(false);
    // } else {
    //   setUcretli(true);
    // }
  };
  const onChangeDays = (v) => {
    console.log(v.target.value);
    setDays(v.target.value);
  };
  return (
    <div className="container mx-auto min-h-screen">
      <Modal visible={sigModal} onCancel={close} footer={null} closable={false}>
        <div>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          {/* Button to trigger save canvas image */}
          <Space className="mt-3" size="small">
            <Button type="primary" icon={<SaveOutlined />} onClick={save}>
              Save
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200"
              icon={<ClearOutlined />}
              onClick={clear}
            >
              Clear
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200 text-red-600"
              onClick={() => setSigModal(false)}
            >
              Close
            </Button>
          </Space>
        </div>
      </Modal>
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              VALUES EDUCATION / <b className="text-red-500">2023 - Term 3</b>
              <br />
              ENROLMENT FORM (GIRLS)
            </h2>
            <br />
          </div>
          <div className="border border-gray-300 bg-f5f5f5 p-2 lg:p-8 shadow-lg mx-auto max-w-4xl">
            <Form
              form={form}
              onFinish={ucretli ? onFinish : onSubmit}
              onFieldsChange={formChanged}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
              initialValues={{ days: 3 }}
            >
              <Form.Item
                label="Student full name"
                name="fullname"
                rules={[{ required: true, message: "This field required!" }]}
                help="If it is a past student, the form will be filled automatically."
              >
                <Input
                  placeholder="Type student fullname"
                  onBlur={(v) => {
                    console.log("blur", v.target.value);
                    AdminService.checkExistingGirl(v.target.value).then(
                      (data) => {
                        console.log(data.data);
                        form.setFieldsValue(data.data);
                      }
                    );
                  }}
                />
              </Form.Item>
              {/* <Form.Item
                label="Cinsiyet"
                name="gender"
                rules={[{ required: true, message: "Gender is required!" }]}
              >
                <Select placeholder="Lütfen cinsiyet seçiniz">
                  {["Erkek", "Kız"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item
                label="Year level"
                name="year"
                rules={[{ required: true, message: "Year Level is required!" }]}
              >
                <Select placeholder="Select year level" onChange={yearChanged}>
                  {["Year 7", "Year 8", "Year 9", "Year 10", "Year 11"].map(
                    (g) => (
                      <Select.Option key={g} value={g}>
                        {g}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Does your child <br />
                    have allergies
                  </span>
                }
                name="isAllergie"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Please select" onChange={allergieChanged}>
                  {["Yes", "No"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {isAllergic && (
                <Form.Item
                  label="Details"
                  name="allergie"
                  rules={[{ required: true, message: "Details is required!" }]}
                >
                  <Input placeholder="Please type details" />
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  Are there any health conditions about your child that we
                  should know about?
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                name="health"
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <Input placeholder="Type details (if any)" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">Parent/Guardian - 1 Details</p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Full name"
                name="gfullname"
                rules={[{ required: true, message: "Full name is required!" }]}
              >
                <Input placeholder="Type fullname" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="gphone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Type phone" />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Type e-mail address" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">Parent/Guardian - 2 Details</p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Full name"
                name="gfullname2"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Type full name" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="gphone2"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Type phone" />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email2"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Type e-mail address" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">
                  Emergency Contact (Different from parent)
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Contact Full name"
                name="efullname"
                rules={[{ required: true, message: "Full name is required!" }]}
              >
                <Input placeholder="Type fullname" />
              </Form.Item>
              <Form.Item
                label="Contact Phone"
                name="ephone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Type phone" />
              </Form.Item>
              {/*   <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">
                  How many days will you attend the programs?
                </p>
              </Form.Item>
             <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
                className="-mt-4"
                name="days"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Radio.Group onChange={onChangeDays} value={days}>
                  <Radio value={1}>Only Fridays</Radio>
                  <Radio value={2}>Only Saturdays</Radio>
                  <Radio value={3}>Fridays and Saturdays</Radio>
                </Radio.Group>
              </Form.Item> */}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  <b>RISK AWARENESS & PERMISSION SLIP</b>{" "}
                </p>
                <p className="mb-4">
                  Galaxy Foundation Values Education Program administrates /
                  convenes / participates in a number of welfare activities in
                  which many students, including students of Amity College,
                  participate. While Galaxy Foundation takes measures to ensure
                  the welfare activities are as safe as possible for
                  participants, there is still a risk that students can be
                  injured as a result of their participation in these welfare
                  activities.
                </p>
                <p>
                  Injury may occur while the student is engaging in an activity
                  or travelling to and from an activity. The injury may result
                  from a student’s actions or that of others. In order to
                  prevent any serious injury/incident, students must avoid
                  engaging in any dangerous act and conduct themselves safely at
                  all times. If a student has a pre-existing injury,
                  participating in these activities could result in an
                  exacerbation of that injury. It is the student’s
                  responsibility to ensure that their belongings are not lost,
                  stolen, damaged or destroyed.
                </p>
                <p>
                  <b>CONSENT (please read carefully):</b>
                </p>{" "}
                <ol className="list-decimal">
                  <li>
                    I agree to my daughter taking part in the activities of the
                    program. Those are regular programs on Saturdays or Sundays
                    and from time to time some excursions with a small group or
                    big group.
                  </li>
                  <li>
                    I confirm to the best of my knowledge that my son/ daughter
                    does not suffer from any medical condition other than those
                    listed above.
                  </li>
                  <li>
                    I consent to my daughter travelling by any form of public
                    transport, minibus or motor vehicle organised by the Galaxy
                    Values Education team to any event in which the program is
                    participating. In addition, I consent to my daughter
                    travelling by staff with green P or full license who
                    obtained approval from the Foundation administrators.
                  </li>
                  <li>
                    I understand that usual program venues are ;
                    <div className="ml-4">
                      - Amity College Campuses
                      <br /> - Amity College,
                      <br /> - Pastoral Care Houses
                      <br /> - Students’ Houses <br /> - Galaxy Foundation
                      Volunteers’ Houses
                    </div>
                  </li>
                  <li>
                    I give permission to my child to attend the above listed
                    places with teacher or mentor supervision.
                  </li>
                  <li>
                    I understand that the Galaxy Values Education activities
                    include a photography and film component in which my child
                    will be both photographed and a photographer. I understand
                    that these images will be part of a final exhibit, future
                    Galaxy Foundation programming, and press related to Galaxy
                    Foundation.
                  </li>
                  <li>
                    In the event of any illness or accident, I authorise the
                    obtaining on my behalf of such medical assistance as my
                    child may require. I accept all operation, blood transfusion
                    and/or anaesthetic risks involved and the responsibility for
                    payment of any expense thus incurred. Signature of
                    Parent/Guardian
                  </li>
                </ol>
              </Form.Item>
              <Form.Item label="Parent Consent">
                <Checkbox onChange={onChange} defaultChecked={true}>
                  I, <b>{veli} </b> consent to my child <b>{ogrenci}</b> of
                  class <b>{sinif}</b> to participate in the excursions /
                  activities.
                </Checkbox>
              </Form.Item>
              <Form.Item label="Signature" required>
                <Button
                  onClick={() => setSigModal(true)}
                  className="text-sky-600"
                >
                  Open signature panel
                </Button>

                <br />
                <br />

                {imageURL ? (
                  <img
                    src={imageURL}
                    alt="my signature"
                    style={{
                      border: "1px solid #ddd",
                      padding: "15px",
                      backgroundColor: "white",
                      width: "150px",
                    }}
                  />
                ) : null}
              </Form.Item>
              {ucretli && (
                <>
                  <Form.Item
                    wrapperCol={
                      lg && {
                        offset: 6,
                        span: 18,
                      }
                    }
                  >
                    <h4 className="text-base">
                      If you like to make the payment now, please fill in the
                      details below.
                      <br />
                      <br />
                      Please Type Your Credit/Debit Card Details
                    </h4>
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "18px",
                            fontFamily: "monospace",
                            color: "#424770",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "red",
                          },
                        },
                      }}
                      className="st-form"
                    />
                    <Button
                      loading={loading}
                      type="primary"
                      htmlType="submit"
                      disabled={!stripe || !elements || !onay || !imageURL}
                      block
                      style={{ maxWidth: "500px" }}
                      size="large"
                      icon={<CreditCardOutlined />}
                    >
                      Pay $150 and Submit Form
                    </Button>
                  </Form.Item>
                </>
              )}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
                className="mt-6"
              >
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  block
                  size="large"
                  style={{ maxWidth: "500px" }}
                  disabled={!onay || !imageURL}
                  onClick={() => onSubmit()}
                >
                  Submit Form without payment
                </Button>
                <br />
                <br />
                <Button
                  type="default"
                  htmlType="reset"
                  style={{ maxWidth: "500px" }}
                  icon={<ClearOutlined />}
                  size="large"
                  block
                >
                  Clear Form
                </Button>
                <br />
              </Form.Item>
            </Form>
            {ucretli && (
              <img
                src={require("../assets/images/secure-stripe.png").default}
                alt="secure"
                className="w-full max-w-md mx-auto"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
