import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { AdminService } from "src/resources/AdminService";

const AssessmentForm = () => {
  const [form] = Form.useForm();
  const router = useHistory();
  const [imageURL, setImageURL] = useState(null);
  const [sigModal, setSigModal] = useState(false);
  const sigCanvas = useRef({});
  const [ogrenci, setOgrenci] = useState("......");
  const [veli, setVeli] = useState("......");
  const [sinif, setSinif] = useState("......");
  const [onay, setOnay] = useState(true);

  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const close = () => setSigModal(false);

  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setSigModal(false);
  };
  const onReset = () => {
    form.resetFields();
    setOgrenci(".....");
    setSinif(".....");
    setVeli("......");
  };

  const onChange = (e) => {
    setOnay(e.target.checked);
  };
  const formChanged = (a, b) => {
    setOgrenci(form.getFieldValue("fullname"));
    setVeli(form.getFieldValue("gfullname"));
    setSinif(form.getFieldValue("year"));
  };
  const onFinish = (values) => {
    console.log(values);
    if (imageURL === null) {
      notification.error({
        message: "Error",
        description: "Please sign",
      });
      return;
    }
    values = { ...values, signature: imageURL };
    console.log(values);
    AdminService.newAssessments(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Form saved!",
      });
      onReset();
      router.replace("/completed");
    });
  };

  const yearChanged = (v) => {
    console.log(v);
  };
  return (
    <div className="container mx-auto min-h-screen">
      <Modal visible={sigModal} onCancel={close} footer={null} closable={false}>
        <div>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          {/* Button to trigger save canvas image */}
          <Space className="mt-3" size="small">
            <Button type="primary" icon={<SaveOutlined />} onClick={save}>
              Save
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200"
              icon={<ClearOutlined />}
              onClick={clear}
            >
              Clear
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200 text-red-600"
              onClick={() => setSigModal(false)}
            >
              Close
            </Button>
          </Space>
        </div>
      </Modal>
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              VALUES EDUCATION
            </h2>
          </div>
          <br />
          <p className="font-bold"> Laser Skirmish & Bowling Activity</p>
          <br />
          <p>Dear Parents,</p>
          <br />
          <p>
            Galaxy girls values education team has organised a Laser Skirmish &
            Bowling activity on Saturday, 19 March.
            <br />
          </p>
          <p>The details of the activity are as follows:</p>
          <br />
          <p>
            The Saturday program will start at the usual time at 10.00 am. You
            need to drop your child off at 24 Venezia Street/ Prestons. After
            having lunch, students will be picked up by the bus to the Zone
            Bowling Orange Grove. After bowling and laser skirmish, students
            will walk to Krispy Kreme Liverpool as a group with supervision.The
            bus will drop them back to Amity College Prestons Campus at 3.30 pm.
            You need to pick up your child from the campus.
          </p>{" "}
          <br />
          <p>
            <b> Cost:</b> Activity fee is included in the termly fee. Students
            can bring a maximum $10 for Krispy Kreme.
          </p>
          <p>
            <b>Drop off: </b>10.00 am 24 Venezia Street/ Prestons
          </p>
          <p>
            <b> Pick up:</b> 3.30 pm Amity College Prestons Campus- Girls High
            School
          </p>
          <p>
            <b>Risk assessment: </b>
            Please be informed that the risk includes possible injuries during
            laser & bowling games, bus travelling and walking to the venue even
            though full supervision is provided by teachers.{" "}
          </p>
          <br />
          <p>Regards, </p>
          <br />
          <p>
            <b>Galaxy Values Education Team</b>{" "}
          </p>
          <br />
          <p>
            If you would like your child to participate in this activity, please
            complete the permission slip below by Friday, the 18th of March.
          </p>
          <br />
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="max-w-md"
            onFieldsChange={formChanged}
            onReset={onReset}
          >
            <Form.Item
              label="Student fullname"
              name="fullname"
              rules={[{ required: true, message: "This field required!" }]}
            >
              <Input placeholder="Type student fullname" />
            </Form.Item>
            <Form.Item
              label="Year level"
              name="year"
              rules={[{ required: true, message: "Year Level is required!" }]}
            >
              <Select placeholder="Select year level" onChange={yearChanged}>
                {["Year 7", "Year 8", "Year 9", "Year 10", "Year 11"].map(
                  (g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Parent/Guardian fullname"
              name="gfullname"
              rules={[{ required: true, message: "This field required!" }]}
            >
              <Input placeholder="Type parent/guardian fullname" />
            </Form.Item>
            <Form.Item label="Parent Consent">
              <Checkbox onChange={onChange} defaultChecked={true}>
                I, <b>{veli} </b>, give permission for my child <b>{ogrenci}</b>{" "}
                of class <b>{sinif}</b> to participate in the Laser Skirmish &
                Bowling activity on Saturday, 19 March.
              </Checkbox>
            </Form.Item>
            <Form.Item label="Signature" required>
              <Button
                onClick={() => setSigModal(true)}
                className="text-sky-600"
              >
                Open signature panel
              </Button>

              <br />
              <br />

              {imageURL ? (
                <img
                  src={imageURL}
                  alt="my signature"
                  style={{
                    border: "1px solid #ddd",
                    padding: "15px",
                    backgroundColor: "white",
                    width: "150px",
                  }}
                />
              ) : null}
            </Form.Item>
            <Form.Item className="mt-6">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                block
                size="large"
                style={{ maxWidth: "500px" }}
                disabled={!onay || !imageURL}
                htmlType="submit"
              >
                Submit Form
              </Button>
              <br />
              <br />

              <Button
                type="default"
                htmlType="reset"
                style={{ maxWidth: "500px" }}
                icon={<ClearOutlined />}
                size="large"
                block
              >
                Clear Form
              </Button>
              <br />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AssessmentForm;
