import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

export default function HomePage() {
  const router = useHistory();
  const tablo = [
    {
      s1: "Year 7 and 8 ",
      s2: "Saturdays / 10:00 am - 2:00 pm",
    },
    {
      s1: "Year 9 - 11 ",
      s2: "Saturdays / 11:00 am - 2:00 pm",
    },
  ];

  const columns = [
    {
      title: "Year Level",
      dataIndex: "s1",
    },
    {
      title: "Time",
      dataIndex: "s2",
    },
  ];
  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              VALUES EDUCATION / <b className="text-red-500">2023 - Term 3</b>
              <br />
              ENROLMENT FORM (FOR GIRLS)
            </h2>
          </div>

          <div className="relative mt-12   lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-bold text-amazonl tracking-tight sm:text-3xl mb-4">
                INFORMATION (2023 - Term 3)
              </h3>
              <div className="space-y-4 text-base">
                <p>Dear Parent/Caregivers,</p>
                <p>
                  Galaxy Foundation is a non-profit organisation that engages in
                  educational, social and charitable activities in Australia. We
                  initiated as a small group of concerned parents into a vibrant
                  organisation with dedicated volunteers and supportive friends.
                  We consistently seek for future social and educational
                  opportunities to further contribute towards the welfare of our
                  community in building a brighter future.
                </p>
                <p>
                  In alignment with our mission, we started Galaxy Values
                  Education last year with your support. As the Galaxy Values
                  Education team, we are very excited to provide your child with
                  opportunities outside school hours this term too. With a
                  variety of programs, your child will:
                </p>
                <p>
                  ● establish bonds with their peers
                  <br />
                  ● have ability to grow spiritually
                  <br />
                  ● engage in group activities such as sports
                  <br />
                  ● share and appreciate refreshments provided by the foundation
                  and its volunteers. <br />
                </p>

                <p>
                  Please note that parents are required to arrange drop off/pick
                  up their children for the programs.
                </p>
                <p>
                  Thank you for your ongoing support and cooperation. If you
                  require further clarification, please do not hesitate to
                  contact us.
                </p>
                <p>
                  Term 3 weekly program details for the girls groups is as
                  follows;
                </p>
                <Table
                  dataSource={tablo}
                  columns={columns}
                  bordered
                  size="small"
                  pagination={false}
                  className="max-w-2xl border border-gray-300"
                />
                <br />

                <p>The total fee for this program is $150 for a term.</p>
              </div>
              <br />
            </div>

            {/* <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto w-full p-2 border border-gray-300 shadow-lg"
                src={require("../assets/images/start.jpg").default}
                alt=""
              />
            </div> */}
            <div className="w-full">
              <Button
                className="my-4 lg:my-0 w-full bg-gradient-to-b from-sky-400 to-sky-600 hover:bg-gradient-to-b hover:from-sky-400 hover:to-sky-600 border-2 border-sky-600 hover:border-sky-800"
                type="primary"
                size="large"
                block
                icon={<ArrowRightOutlined />}
                onClick={() => {
                  router.push("/form");
                }}
              >
                Get Enrolment Form
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
