import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import "react-quill/dist/quill.snow.css";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.less";
import AdminPage from "./components/AdminPage";
import AssessmentForm from "./components/AssessmentForm";
import HomePage from "./components/HomePage";
import HomePageBoys from "./components/HomePageBoys";
import KayitFormu from "./components/KayitFormu";
import KayitFormuBoys from "./components/KayitFormuBoys";
import LoginPage from "./components/LoginPage";
import NotFound from "./components/NotFound";
import SelectGender from "./components/SelectGender";
import Submitted from "./components/Submitted";

function App() {
  const stripePromise = useMemo(
    () =>
      loadStripe(
        "pk_live_51K4fpIJVBRBDOhVwSbKYZdtUEWd76m7VNsov7oM4RRTszSNz0zu8RCfDKsh8hpXQ6VkaqDilnY1tHcHXMV9Ks0qF00X1YrwHvv"
        // "pk_test_51JBXTjE8cXeCmmwbFKaTWIqMXshpGqkklyiaTWUvebeZrdZc999fblpssMaDamjk8FtMayhuzkVzg0G3GRUFAVob007gYJ4aI6"
      ),
    []
  );

  return (
    <div className="bg-eeeeee">
      <Elements stripe={stripePromise}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/start" />
          </Route>
          <Route path="/start" component={SelectGender} />
          <Route path="/boys" component={HomePageBoys} />

          <Route path="/girls" component={HomePage} />
          <Route path="/form" component={KayitFormu} />
          <Route path="/boysform" component={KayitFormuBoys} />
          <Route path="/AssessmentForm" component={AssessmentForm} />

          <Route path="/login" component={LoginPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/completed" component={Submitted} />

          <Route path="/404" component={NotFound} />

          <Route>
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Elements>
    </div>
  );
}

export default App;
